import { storeToRefs } from 'pinia';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';

type MetricsType = {
	goal?: string;
	phone?: string;
	name?: string;
};

export const metricsHelper = (options: MetricsType): void => {
  const appConfigStore = useAppConfigStore();
  const { vars } = storeToRefs(appConfigStore);

  // Отправка YANDEX METRIKA
  if (window?.Ya && options.goal) {
    window?.Ya._metrika.counter.reachGoal(options.goal);
  }

  // Отправка лида
  if (window && window?.ctw && window?.ctw?.createRequest) {
    window.ctw.createRequest(
      'callback_from_forms',
      options?.phone,
      [
        { name: 'Name', value: options?.name }
      ],
      (success: any, data: any) => {
        console.log(success, data);
      }
    );
  }

  const idVkPixel = vars?.value?.['ID VK PIXEL'];
  if (idVkPixel && window && window?._tmr) {
    window._tmr.push({ type: 'reachGoal', id: Number.parseFloat(idVkPixel), goal: 'lead_vk' });
  }
};

export const reachGoalYandexMetrikaBookingRequestSend = (characteristicGroupId?: number): string | undefined => {
  const appConfigStore = useAppConfigStore();
  const { vars } = storeToRefs(appConfigStore);

  if (!characteristicGroupId) {
    return;
  }

  if (characteristicGroupId === 1 && vars.value?.['Метрика страницы товара бронирование (Квартира) (Отправить)']) {
    return vars?.value?.['Метрика страницы товара бронирование (Квартира) (Отправить)'];
  }
  if (characteristicGroupId === 2 && vars.value?.['Метрика страницы товара бронирование (Паркинг) (Отправить)']) {
    return vars?.value?.['Метрика страницы товара бронирование (Паркинг) (Отправить)'];
  }
  if (characteristicGroupId === 3 && vars.value?.['Метрика страницы товара бронирование (Кладовая) (Отправить)']) {
    return vars?.value?.['Метрика страницы товара бронирование (Кладовая) (Отправить)'];
  }
  if (characteristicGroupId === 4 && vars.value?.['Метрика страницы товара бронирование (Коммерческое помещение) (Отправить)']) {
    return vars?.value?.['Метрика страницы товара бронирование (Коммерческое помещение) (Отправить)'];
  }
  if (characteristicGroupId === 5 && vars.value?.['Метрика страницы товара бронирование (Машиноместо) (Отправить)']) {
    return vars?.value?.['Метрика страницы товара бронирование (Машиноместо) (Отправить)'];
  }
  return vars?.value?.['Цель яндекс метрики для "Оставить заявку" на странице товара'];
};

// Метрика бронивароние квартиры
export const reachGoalYandexMetrikaBookingRequestOpen = (characteristicGroupId?: number): string | undefined => {
  const appConfigStore = useAppConfigStore();
  const { vars } = storeToRefs(appConfigStore);

  if (!characteristicGroupId) {
    return;
  }

  if (characteristicGroupId === 1 && vars.value?.['Метрика страницы товара бронирование (Квартира) (Открыть)']) {
    return vars.value?.['Метрика страницы товара бронирование (Квартира) (Открыть)'];
  }
  if (characteristicGroupId === 2 && vars.value?.['Метрика страницы товара бронирование (Паркинг) (Открыть)']) {
    return vars.value?.['Метрика страницы товара бронирование (Паркинг) (Открыть)'];
  }
  if (characteristicGroupId === 3 && vars.value?.['Метрика страницы товара бронирование (Кладовая) (Открыть)']) {
    return vars.value?.['Метрика страницы товара бронирование (Кладовая) (Открыть)'];
  }
  if (characteristicGroupId === 4 && vars.value?.['Метрика страницы товара бронирование (Коммерческое помещение) (Открыть)']) {
    return vars.value?.['Метрика страницы товара бронирование (Коммерческое помещение) (Открыть)'];
  }
  if (characteristicGroupId === 5 && vars.value?.['Метрика страницы товара бронирование (Машиноместо) (Открыть)']) {
    return vars.value?.['Метрика страницы товара бронирование (Машиноместо) (Открыть)'];
  }

  return vars?.value?.['Цель яндекс метрики для "Оставить заявку" на странице товара (открытие формы)'];
};
